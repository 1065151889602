<template>
  <div class="news">
    <TopNav></TopNav>
    <!-- 背景开始 -->
    <div class="top-bg">
      <div class="top-bg-content">
        <div class="top-content-bannar">
          <div class="top-bannar-vis">
            <div>新闻动态</div>
            <div>
              用中标资讯、运营干货一键掌握
            </div>
          </div>
        </div>
      </div>
      <div class="top-bg-content-phone">
        <div class="top-content-bannar">
          <div class="top-bannar-vis">
            <div>优越新闻动态</div>
            <div>
              新闻动态抢先看，从权益输出到品牌赋能
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 背景结束 -->

    <!-- pc开始 -->
    <div class="pcAll">
      <!-- nav切换开始 -->
      <div class="nav-tab">
        <span
          :class="this.tabNum == 1 ? 'tab-active' : ''"
          @click="(tabNum = 1), (currentPage2 = 1)"
          >公司新闻</span
        >
        <!-- <span
          :class="this.tabNum == 2 ? 'tab-active' : ''"
          @click="(tabNum = 2), (currentPage = 1)"
          >行业资讯</span
        > -->
      </div>
      <!-- nav切换结束 -->

      <!-- 新闻整体开始 -->
      <div class="allNews">
        <!-- 公司新闻开始 -->
        <div class="news-box" v-show="this.tabNum == 1">
          <div class="news-content">
            <ul class="news-list">
              <li
                class="news-item"
                v-for="(v, i) in this.newsList"
                :key="i"
                @click="godetail(v.id)"
              >
                <img :src="v.thumbnail" alt="" />
                <p class="item-title">
                  {{ v.title }}
                </p>
                <p class="item-time">{{ v.created_at }}</p>
              </li>
            </ul>
          </div>
          <!-- 分页开始 -->
          <div class="page">
            <van-pagination
              v-model="currentPage"
              :page-count="this.pageTotal"
              @change="changePage"
              force-ellipses
            >
              <template #prev-text>
                <van-icon name="arrow-left" />
              </template>
              <template #next-text>
                <van-icon name="arrow" />
              </template>
              <template #page="{ text }">{{ text }}</template>
            </van-pagination>
          </div>
          <!-- 分页结束 -->
        </div>
        <!-- 公司新闻结束 -->

        <!-- 行业资讯开始 -->
        <!-- <div class="info-box" v-show="this.tabNum == 2">
          <ul class="info-list">
            <li class="info-item" v-for="(v, i) in 9" :key="i">
              <img src="../assets/image/integral/img1.png" alt="" />
              <div class="info-font">
                <p class="info-title">
                  积分商城运营没效果，究竟是哪里出了问题？
                </p>
                <p class="info-time">2022-02-18</p>
                <p class="info-intro">
                  现在很多商家都接入会员积分服务，但是在设置好规则后，却总是运营不好，那么问题到底出现在哪里呢？
                  1.积分规则不合理，舍不得积分兑换带来的成本
                  很多商家在设置积分规则时，把获取积分的门槛设置...
                </p>
              </div>
            </li>
          </ul> -->
        <!-- 分页开始 -->
        <!-- <div class="page">
            <van-pagination
              v-model="currentPage2"
              :total-items="50"
              :show-page-size="6"
              :page-count="12"
            >
              <template #prev-text>
                <van-icon name="arrow-left" />
              </template>
              <template #next-text>
                <van-icon name="arrow" />
              </template>
              <template #page="{ text }">{{ text }}</template>
            </van-pagination>
          </div> -->
        <!-- 分页结束 -->
        <!-- </div> -->
        <!-- 行业资讯结束 -->
      </div>
      <!-- 新闻整体结束 -->
    </div>
    <!-- pc结束 -->

    <!-- 移动开始 -->
    <div class="phoneAll">
      <ul class="newsList">
        <li
          class="newsItem"
          v-for="(v, i) in this.newsList"
          :key="i"
          @click="godetail(v.id)"
        >
          <img class="itemImg" :src="v.thumbnail" alt="" />
          <p class="itemTit">{{ v.title }}</p>
          <div class="itemFoot">
            <span class="itemTime">{{ v.created_at }}</span>
            <a>查看详情 ></a>
          </div>
        </li>
      </ul>
      <!-- 分页开始 -->
      <div class="page">
        <van-pagination
          v-model="currentPage"
          :page-count="this.pageTotal"
          force-ellipses
          @change="changePage"
        >
          <template #prev-text>
            <van-icon name="arrow-left" />
          </template>
          <template #next-text>
            <van-icon name="arrow" />
          </template>
          <template #page="{ text }">{{ text }}</template>
        </van-pagination>
      </div>
      <!-- 分页结束 -->
    </div>
    <!-- 移动结束 -->

    <Bottom></Bottom>
  </div>
</template>

<script>
import "../assets/less/News.less";
import TopNav from "../components/topNav";
import Bottom from "../components/Bottom";

export default {
  data() {
    return {
      currentPage: 1,
      currentPage2: 1,
      tabNum: 1,
      newsList: [],
      pageTotal: 1
    };
  },
  mounted() {
    if (location.href.indexOf("#reloaded") == -1) {
      location.href = location.href + "#reloaded";
      location.reload();
    }
  },
  created() {
    this.axios({
      //请求方式
      method: "POST",
      url: "https://yxwechat.youxiangchewang.com/article/news",
      data: {
        page: this.currentPage,
        page_size: 9
      }
    })
      .then(res => {
        if (res.data.code === 0) {
          this.newsList = res.data.data.list;
          let total = res.data.data.total;
          this.pageTotal = Math.ceil(total / 10);
        } else {
          this.$toast({
            message: res.data.msg,
            forbidClick: true,
            duration: 3000
          });
        }
      })
      .catch(err => {
        this.$toast.clear();
        console.log("err ==> ", err);
      });
  },
  methods: {
    godetail(id) {
      this.$router.push("/newsDetail?id=" + id);
      localStorage.setItem("id", id);
    },
    changePage() {
      this.axios({
        //请求方式
        method: "POST",
        url: "https://yxwechat.youxiangchewang.com/article/news",
        data: {
          page: this.currentPage,
          page_size: 9
        }
      })
        .then(res => {
          if (res.data.code === 0) {
            this.newsList = res.data.data.list;
          } else {
            this.$toast({
              message: res.data.msg,
              forbidClick: true,
              duration: 3000
            });
          }
        })
        .catch(err => {
          this.$toast.clear();
          console.log("err ==> ", err);
        });
    }
  },
  components: {
    TopNav,
    Bottom
  }
};
</script>

<style lang="less" scoped>
/deep/.van-pagination {
  width: 300px;
  margin: 0 auto;
}
</style>
